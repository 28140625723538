import React, { Component } from "react";
import styles from "./styles.scss";
import { Link } from "react-router-dom";
import Button from "components/Button";
import { Mail } from "components/Svg";
import Bioplug from "assets/bioplug.png";
import Bioplug2 from "assets/bioplug-2.png";
import Bioplug3 from "assets/bioplug-3.png";
import { scroller } from "react-scroll";
export default class Header extends Component {
  constructor(props) {
    super(props);
    this.toContact = this.toContact.bind(this);
  }

  toContact(event) {
    event.preventDefault();
    scroller.scrollTo("contact", {
      duration: 1000,
      delay: 100,
      smooth: true
    });
  }

  render() {
    return (
      <header className={styles.header}>
        <div className={`${styles.wrapper} wrapper`}>
          <h1 className={`${styles.logo} mt0 mb0`}>
            <Link to="/">
              <img src={Bioplug3} alt="Bioplug" />
            </Link>
          </h1>
          <Button
            className={"btn--color4"}
            extraClasses={styles.btn}
            href="#contact"
            onClick={this.toContact}
          >
            Contact <Mail extraClasses="ml8" />
          </Button>
        </div>
      </header>
    );
  }
}
