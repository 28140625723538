import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'components/Layout/index.jsx';

export default class Test extends Component {
	render() {
		return (
			<Layout>
				<Helmet>
					<title>Test</title>
					<meta name="description" content="Test" />
				</Helmet>
				<h1>Test</h1>
			</Layout>
		);
	}
}
