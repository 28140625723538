import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route } from 'react-router-dom';
import { AppContainer } from 'react-hot-loader';
import Index from 'pages/index';
import Test from 'pages/test';
import NotFound from 'pages/not-found';
import { spring, AnimatedSwitch } from 'react-router-transition';
import history from 'helpers/history';
import ReactGA from 'react-ga';

// we need to map the `scale` prop we define below
// to the transform style property
function mapStyles(styles) {
	return {
		// position: styles.foo <= 1 ? 'relative' : 'absolute',
		position: styles.foo <= 1 ? 'static' : 'absolute',
		width: '100%',
		opacity: styles.opacity,
		transform: `translateY(${styles.x}px)`
	};
}

// wrap the `spring` helper to use a bouncy config
function bounce(val) {
	return spring(val, {
		stiffness: 200,
		damping: 12
	});
}
// function opacity(val) {
// 	return spring(val, {
// 		stiffness: 230,
// 		damping: 10
// 	});
// }

// child matches will...
const bounceTransition = {
	// start in a transparent, upscaled state
	atEnter: {
		opacity: 0,
		foo: 0,
		// scale: 1.2,
		x: -24
	},
	// leave in a transparent, downscaled state
	atLeave: {
		opacity: bounce(0),
		foo: 2,
		// scale: bounce(0.8),
		x: bounce(-24)
	},
	// and rest at an opaque, normally-scaled state
	atActive: {
		opacity: bounce(1),
		foo: 1,
		// scale: bounce(1),
		x: bounce(0)
	}
};

const App = () => [
	ReactGA.initialize('UA-120836476-1'),
	ReactGA.pageview(window.location.pathname + window.location.search),
	<AppContainer key="container">
		<div>
			<Router history={history}>
				<AnimatedSwitch
					key="switch"
					atEnter={bounceTransition.atEnter}
					atLeave={bounceTransition.atLeave}
					atActive={bounceTransition.atActive}
					mapStyles={mapStyles}
					className="route-wrapper">
					<Route exact path="/" component={Index} />
					<Route exact path="/test" component={Test} />
				</AnimatedSwitch>
			</Router>
		</div>
	</AppContainer>
];
ReactDOM.render(<App />, document.getElementById('root'));
