import React from 'react';
export const ArrowLeft = ({ className, extraClasses }) => (
	<svg
		width="32px"
		height="32px"
		viewBox="0 0 32 32"
		className={`${className} icon ${
			extraClasses !== undefined ? ` ${extraClasses}` : ''
		}`}>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				transform="translate(-624.000000, -934.000000)"
				fillRule="nonzero"
				fill="#016935">
				<g transform="translate(0.000000, 120.000000)">
					<g transform="translate(48.000000, 797.000000)">
						<g transform="translate(560.000000, 1.000000)">
							<polygon
								transform="translate(32.000000, 32.000000) rotate(-180.000000) translate(-32.000000, -32.000000) "
								points="32 16 29.18 18.82 40.34 30 16 30 16 34 40.34 34 29.18 45.18 32 48 48 32"
							/>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export const ArrowRight = ({ className, extraClasses }) => (
	<svg
		width="32px"
		height="32px"
		viewBox="0 0 32 32"
		version="1.1"
		className={`${className} icon ${
			extraClasses !== undefined ? ` ${extraClasses}` : ''
		}`}>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				transform="translate(-624.000000, -934.000000)"
				fillRule="nonzero"
				fill="#016935">
				<g transform="translate(0.000000, 120.000000)">
					<g transform="translate(48.000000, 797.000000)">
						<g transform="translate(560.000000, 1.000000)">
							<polygon points="32 16 29.18 18.82 40.34 30 16 30 16 34 40.34 34 29.18 45.18 32 48 48 32" />
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export const ChevronDown = ({ extraClasses }) => (
	<svg
		width="32px"
		height="20px"
		viewBox="0 0 32 20"
		version="1.1"
		className={`icon${extraClasses !== undefined ? ` ${extraClasses}` : ''}`}>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				transform="translate(-782.000000, -864.000000)"
				fillRule="nonzero"
				fill="#016935">
				<g transform="translate(766.000000, 842.000000)">
					<polygon points="44.24 22 32 34.2133333 19.76 22 16 25.76 32 41.76 48 25.76" />
				</g>
			</g>
		</g>
	</svg>
);

export const Mail = ({ extraClasses }) => (
	<svg
		width="16px"
		height="13px"
		viewBox="0 0 16 13"
		version="1.1"
		className={`icon${extraClasses !== undefined ? ` ${extraClasses}` : ''}`}>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				transform="translate(-1149.000000, -54.000000)"
				fillRule="nonzero"
				fill="#FFFFFF">
				<g transform="translate(1050.000000, 36.000000)">
					<g transform="translate(25.000000, 14.000000)">
						<path d="M88.4,4 L75.6,4 C74.72,4 74.008,4.72 74.008,5.6 L74,15.2 C74,16.08 74.72,16.8 75.6,16.8 L88.4,16.8 C89.28,16.8 90,16.08 90,15.2 L90,5.6 C90,4.72 89.28,4 88.4,4 Z M88.4,7.2 L82,11.2 L75.6,7.2 L75.6,5.6 L82,9.6 L88.4,5.6 L88.4,7.2 Z" />
					</g>
				</g>
			</g>
		</g>
	</svg>
);
