import React, { Component } from 'react';
import styles from './styles.scss';
import LazyLoad from 'react-lazyload';

export default class TextBanner extends Component {
	render() {
		return (
			<article className={`${styles.banner}`} id={this.props.id}>
				<LazyLoad height={1200}>
					<div
						className={styles.image}
						style={{ backgroundImage: `url(${this.props.image})` }}
					/>
					<div className={`${styles.wrapper} wrapper`}>
						<h1
							className={`${
								styles.title
							} title color1 mt0 mb32 uppercase f-black ls25 color1`}
							dangerouslySetInnerHTML={{ __html: this.props.title }}
						/>
						<p className={`${styles.description} color2 mt0 mb0`}>
							{this.props.description}
						</p>
					</div>
				</LazyLoad>
			</article>
		);
	}
}
