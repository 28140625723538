import React, { Component } from 'react';
import styles from './styles.scss';
import Background from 'assets/pattern.png';
export default class Pattern extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { type } = this.props;
    return (
      <div
        className={[
          styles.pattern,
          type === 'footer' ? styles['pattern--footer'] : ''
        ].join(' ')}>
        <img src={Background} alt="" />
      </div>
    );
  }
}
