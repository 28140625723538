import React, { Component } from 'react';
import styles from './styles.scss';
import Pattern from 'components/Pattern';

export default class Footer extends Component {
  render() {
    return (
      <footer className={`${styles.footer}`}>
        <div className={`${styles.wrapper} wrapper`}>
          <b className="uppercase">&copy; {new Date().getFullYear()} Bioplug</b>
          <span className={`${styles.tagline} f-light`}>
            Let’s clean the ocean by the roots.
          </span>
          <Pattern type="footer" />
        </div>
      </footer>
    );
  }
}
