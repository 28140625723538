import React, { Component } from 'react';
import styles from './styles.scss';
import Swiper from 'swiper';
import { ArrowLeft, ArrowRight } from 'components/Svg';
import Pattern from 'components/Pattern';

export default class ImageSlider extends Component {
  componentDidMount() {
    new Swiper(`.${styles.slider}`, {
      effect: 'fade',
      // loop: true,
      // loopAdditionalSlides: 3,
      // loopedSlides: 3,
      wrapperClass: styles.list,
      slideClass: styles.item,
      slideActiveClass: styles['item--is-active'],
      slideNextClass: styles['item--next'],
      slideDuplicatedNextClass: styles['item--next-duplicate'],
      slidesPerView: 'auto',
      preloadImages: false,
      // touchAngle: 90,
      // threshold: 0.5,
      navigation: {
        nextEl: `.${styles['navigation-button--next']}`,
        prevEl: `.${styles['navigation-button--prev']}`,
        disabledClass: styles['navigation-button--is-disabled']
      },
      lazy: {
        loadPrevNext: true,
        elementClass: styles.image,
        loadingClass: styles['image--is-loading'],
        loadedClass: styles['image--is-loaded'],
        preloaderClass: styles.preloader
      },
      pagination: {
        el: `.${styles.pagination}`,
        type: 'bullets',
        clickable: true,
        bulletClass: styles['pagination-item'],
        bulletActiveClass: styles['pagination-item--is-active'],
        clickableClass: styles.pagination
      },
      on: {
        slideChange: function() {
          this.lazy.loadInSlide(this.activeIndex);
        }
      }
    });
  }
  render() {
    const { pattern } = this.props;
    return (
      <div className={styles.wrap}>
        <div
          className={`${styles.slider} ${
            this.props.type === 'intro' ? ` ${styles['slider--intro']}` : null
          }`}>
          <ul className={styles.list}>
            {this.props.items.length > 0 &&
              this.props.items.map((item, i) => (
                <li className={styles.item} key={i}>
                  <div className={styles.preloader} />
                  <figure className={styles.image} data-background={item.src} />
                </li>
              ))}
          </ul>
          <div className={styles.controls}>
            <a href="#!" className={styles['navigation-button--prev']}>
              <ArrowLeft className={styles.icon} />
            </a>
            <div className={styles.pagination} />
            <a href="#!" className={styles['navigation-button--next']}>
              <ArrowRight className={styles.icon} />
            </a>
          </div>
        </div>
        {pattern && <Pattern />}
      </div>
    );
  }
}
