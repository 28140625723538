import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "components/Layout";
import Image from "components/Image";
import ImageSlider from "components/ImageSlider";
import ImageText from "components/ImageText";
import TextBanner from "components/TextBanner";
import Contact from "components/Contact";
import LazyLoad from "react-lazyload";
import Image1 from "assets/image-1.jpg";
import Image2 from "assets/image-2.jpg";
import Image3 from "assets/image-3.jpg";
import Image4 from "assets/image-4.jpg";
import Image5 from "assets/image-5.jpg";
import Image6 from "assets/image-6.jpg";

export default class Index extends Component {
  render() {
    const firstSlider = [
      {
        src: Image1,
        title: "title",
        alt: "alt"
      },
      {
        src: Image2,
        title: "title 2",
        alt: "alt 2"
      },
      {
        src: Image3,
        title: "title 3",
        alt: "alt 2"
      }
    ];
    const secondSlider = [
      {
        src: Image4,
        title: "title",
        alt: "alt"
      },
      {
        src: Image6,
        title: "title 3",
        alt: "alt 2"
      }
    ];

    return (
      <Layout>
        <Helmet>
          <title>Bioplug — Let’s clean the ocean by the roots.</title>
          <meta property="og:site_name" content="Bioplug" />
          <meta
            property="og:title"
            content="Bioplug — Let’s clean the ocean by the roots."
          />
          <meta
            name="description"
            content="Met de pluggen van Bioplug bent u naast een optimale groei van uw planten verzekerd van een milieuvriendelijk product. De paperpotten van Bioplug zijn in zijn geheel gemaakt van materiaal dast vervaardigd is uit natuurlijke grondstoffen. De pluggen bevatten geen plastic. En dat is uniek. De wortels kunnen zich snel en uniform ontwikkelen. En u bent weer een stapje dichter bij het uitbannen van plastic."
          />
          <meta
            property="og:description"
            content="Met de pluggen van Bioplug bent u naast een optimale groei van uw planten verzekerd van een milieuvriendelijk product. De paperpotten van Bioplug zijn in zijn geheel gemaakt van materiaal dat vervaardigd is uit natuurlijke grondstoffen. De pluggen bevatten geen plastic. En dat is uniek. De wortels kunnen zich snel en uniform ontwikkelen. En u bent weer een stapje dichter bij het uitbannen van plastic."
          />
          <meta property="og:image" content="../assets/share.jpg" />
          <meta property="og:url" content="https://bioplug.eu/" />
          <title>Bioplug — Let’s clean the ocean by the roots.</title>
        </Helmet>
        <LazyLoad height={120}>
          <Image>
            <ImageSlider pattern type="intro" items={firstSlider} />
            <ImageText
              type="intro"
              title={
                '<b class="color1">Bioplug</b> <br/><span class="color2">100%</span> <span class="color1">biobased</span>'
              }
              description="Met de pluggen van Bioplug bent u naast een optimale groei van uw planten verzekerd van een milieuvriendelijk product. De paperpotten van Bioplug zijn in zijn geheel gemaakt van materiaal dat vervaardigd is uit natuurlijke grondstoffen. De pluggen bevatten geen plastic. En dat is uniek. De wortels kunnen zich snel en uniform ontwikkelen. En u bent weer een stapje dichter bij het uitbannen van plastic."
            />
          </Image>
        </LazyLoad>
        <TextBanner
          id="about"
          title="Over ons"
          description="Bioplug is een jong Westlands bedrijf, dat gevestigd is in het hart van de Nederlandse tuinbouw. Hierdoor kunnen de bestellingen snel geleverd worden."
          image="https://images.unsplash.com/photo-1480866593555-32dc933d6a77?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=2991c2ee41fef63de7b9dcd18667629b&auto=format&fit=crop&w=1490&q=80"
        />
        <LazyLoad height={1400}>
          <Image type="right">
            <ImageText
              align="right"
              title={
                '<b class="color4">Vijf<br/><span class="color2">verschillende</span><br/>maten</b>'
              }
              description="<b>24mm</b> (126 gaats tray)<br/>
              <b>28mm</b> (104 gaats tray)<br/>
              <b>33mm</b> (84 gaats tray)<br/>
              <b>38mm</b> (60 gaats tray)<br/>
              <b>45mm</b> (40 gaats tray)"
            />
            <ImageSlider items={secondSlider} />
          </Image>
        </LazyLoad>
        <Contact />
      </Layout>
    );
  }
}
