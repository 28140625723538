import React, { Component } from 'react';
import styles from './styles.scss';

export default class Image extends Component {
  componentDidMount() {}
  render() {
    return (
      <section
        className={`${styles.image}${
          this.props.type === 'right' ? ` ${styles['image--right']}` : ''
        }`}>
        {this.props.children}
      </section>
    );
  }
}
