import React, { Component } from 'react';
import styles from './styles.scss';
import { ChevronDown } from 'components/Svg';
import Button from 'components/Button';
import { scroller } from 'react-scroll';
export default class ImageText extends Component {
  constructor(props) {
    super(props);
    this.toAbout = this.toAbout.bind(this);
  }

  toAbout(event) {
    event.preventDefault();
    scroller.scrollTo('about', {
      duration: 800,
      delay: 10,
      smooth: true
    });
  }

  render() {
    return (
      <article
        className={`${styles.content} ${
          this.props.type === 'intro' ? ` ${styles['content--intro']}` : ''
        }${
          this.props.align === 'right' ? ` ${styles['content--right']}` : ''
        }`}>
        <h1
          className={`${styles.title} title mt0 mb32 uppercase f-bold ls25`}
          dangerouslySetInnerHTML={{ __html: this.props.title }}
        />
        <p
          className={`${styles.description} mt0 mb0`}
          dangerouslySetInnerHTML={{ __html: this.props.description }}
        />
        {this.props.type === 'intro' && (
          <Button
            type="icon"
            href="#about"
            extraClasses="mt24"
            onClick={this.toAbout}>
            <ChevronDown />
          </Button>
        )}
      </article>
    );
  }
}
