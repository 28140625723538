import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.scss';
export default class Button extends Component {
	render() {
		return this.props.target === '_blank' ? (
			<a
				onClick={this.props.onClick}
				className={`${
					this.props.extraClasses !== undefined
						? `${this.props.extraClasses} `
						: ''
				}${
					this.props.className !== undefined
						? `${styles[`${this.props.className}`]} `
						: ''
				}${styles.btn} ${
					this.props.type === 'icon' ? ` ${styles['btn--icon']}` : ''
				}`}
				href={this.props.href}
				rel={this.props.rel}
				target="_blank">
				{this.props.children}
			</a>
		) : (
			<Link
				onClick={this.props.onClick}
				className={`${
					this.props.extraClasses !== undefined
						? `${this.props.extraClasses} `
						: ''
				}${
					this.props.className !== undefined
						? `${styles[`${this.props.className}`]} `
						: ''
				}${styles.btn} ${
					this.props.type === 'icon' ? ` ${styles['btn--icon']}` : ''
				}`}
				to={this.props.href}
				rel={this.props.rel}>
				{this.props.children}
			</Link>
		);
	}
}
