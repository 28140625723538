import React, { Component } from "react";
import styles from "./styles.scss";
import Button from "components/Button";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

const GMap = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyD39IhMiXRTRTXI3LvwMan0vlM1ug1phyk&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100px`, width: `100px` }} />,
    containerElement: <div className={styles.frame} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultZoom={14}
    defaultCenter={{ lat: 52.0117801, lng: 4.202078 }}
    options={{
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      styles: [
        {
          featureType: "landscape.man_made",
          elementType: "geometry",
          stylers: [
            {
              color: "#f7f1df"
            }
          ]
        },
        {
          featureType: "landscape.natural",
          elementType: "geometry",
          stylers: [
            {
              color: "#d0e3b4"
            }
          ]
        },
        {
          featureType: "landscape.natural.terrain",
          elementType: "geometry",
          stylers: [
            {
              visibility: "off"
            }
          ]
        },
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [
            {
              visibility: "off"
            }
          ]
        },
        {
          featureType: "poi.business",
          elementType: "all",
          stylers: [
            {
              visibility: "off"
            }
          ]
        },
        {
          featureType: "poi.medical",
          elementType: "geometry",
          stylers: [
            {
              color: "#fbd3da"
            }
          ]
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [
            {
              color: "#bde6ab"
            }
          ]
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [
            {
              visibility: "off"
            }
          ]
        },
        {
          featureType: "road",
          elementType: "labels",
          stylers: [
            {
              visibility: "off"
            }
          ]
        },
        {
          featureType: "road.highway",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ffe15f"
            }
          ]
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#efd151"
            }
          ]
        },
        {
          featureType: "road.arterial",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ffffff"
            }
          ]
        },
        {
          featureType: "road.local",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "black"
            }
          ]
        },
        {
          featureType: "transit.station.airport",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#cfb2db"
            }
          ]
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              color: "#a2daf2"
            }
          ]
        }
      ]
    }}
  >
    <Marker position={{ lat: 52.01178017, lng: 4.202078 }} />
  </GoogleMap>
));

export default class Contact extends Component {
  componentDidMount() {}
  render() {
    return (
      <section className={styles.contact} id="contact">
        <div className={styles.map}>
          <GMap />
          <Button
            href="https://www.google.com/maps/dir//Nursery+Andr%C3%A9+den+Drijver,+Dortlaan+13,+2671+LN+Naaldwijk/@52.0117007,4.1995568,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47c5b214e9ced853:0xb3c22a7de05ae732!2m2!1d4.2017508!2d52.0116974"
            target="_blank"
            rel="noopener"
            className={`btn--color4`}
            extraClasses={styles.btn}
          >
            Routebeschrijving
          </Button>
        </div>
        <article className={styles.detail}>
          <h1
            className={`${styles.title} color2 title mt0 mb32 uppercase f-black ls25`}
          >
            Wilt u meer weten?
          </h1>
          <p className={`${styles.description} mt0`}>
            Vraag <b>Andre den Drijver</b> naar de mogelijkheden.
          </p>
          <address className={styles.address}>
            <b>Bioplug</b>
            <br />
            Andre den Drijver
            <br />
            Dortlaan 7<br />
            2671 LN Naaldwijk
            <br />
            <a href="mailto:sales@bioplug.eu">sales@bioplug.eu</a>
            <br />
            <a href="tel:+310654337132">06 54 33 71 32</a>
          </address>
        </article>
      </section>
    );
  }
}
