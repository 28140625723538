import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { layout } from './styles.scss';
import globalStyles from 'styles/global.scss';
import Header from 'components/Header';
import Footer from 'components/Footer';
export default class Layout extends Component {
  render() {
    return (
      <div className={layout}>
        <Helmet>
          <style
            type="text/css"
            dangerouslySetInnerHTML={{ __html: globalStyles }}
          />
        </Helmet>
        <Header />
        {this.props.children}
        <Footer />
      </div>
    );
  }
}
